import { RechercheData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../core/sysext/Boutique/containers/Recherche/components/List/Item';
import params from '../../core/sysext/Boutique/containers/Recherche/params.json';
import CMSView from '../../core/sysext/CMS/components/View';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import List from '../../core/sysext/CMS/containers/Boutique/components/new/List';

const RecherchesPage: FC<PageProps> = props => {
  const { location } = props;
  const { t } = useTranslation();

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  const model = new RechercheData({
    boutique,
    params,
    orderDirection: 'desc',
    orderField: 'nbSearchs',
  });

  return (
    <Backoffice location={location}>
      <CMSView>
        <List
          {...props}
          displayNavigation
          formName="recherche"
          item={Item}
          itemPathnamePrefix="/boutique/recherches/"
          itemShowInPopup
          model={model}
          nbParPage={50}
          title={t('recherches')}
        />
      </CMSView>
    </Backoffice>
  );
};

export default RecherchesPage;
